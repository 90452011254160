.nav-item {
  @apply p-2 ml-2 rounded-l flex items-center text-base  text-white;
  .icon {
    @apply w-6 h-6 mr-1 text-base flex items-center justify-center;
    // filter: sepia(100%) saturate(200%) brightness(70%) hue-rotate(180deg);
    svg{
      @apply w-6 h-6
    }
  }
  &:hover {
    @apply cursor-pointer bg-white bg-opacity-10 opacity-100;
  }
  &.active {
    @apply bg-emerald-200 opacity-100 text-emerald-900;
  }
}
