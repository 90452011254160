table.e-table {
  thead {
    tr {
      @apply border-b border-slate-500;
      th {
        @apply  font-medium ;
      }
    }
  }
  tbody {
    tr {
      td {
        // @apply text-base;
        @apply  text-slate-800;
      }

      &:hover {
        @apply bg-slate-100;
      }
    }
  }
  .link {
    @apply text-primary-800 font-medium;
    &:hover {
      @apply cursor-pointer underline;
    }
  }
  &.end-btns {
    tbody {
      tr {
        td:last-of-type {
          @apply flex justify-end gap-2;
          // div {
          //     svg {
          //       @apply w-5 h-5 opacity-30;
          //       &:hover {
          //         @apply opacity-100  cursor-pointer text-primary-500;
          //       }
          //       &.delete {

          //           &:hover {
          //               @apply text-danger;
          //           }
          //       }
          //     }
          // }
        }
      }
    }
  }
}
