.btn {
  @apply inline-flex flex-row flex-shrink-0 gap-2 justify-center items-center py-2 px-3.5 rounded cursor-pointer transition-all h-8 select-none;
  &:hover {
    @apply ring-2 ring-emerald-100 shadow-md shadow-emerald-100;
  }
  &--btn--loader {
    @apply cursor-progress;
  }
  &--primary {
    @apply bg-emerald-500 text-white;
    &:hover {
      @apply bg-emerald-600;
    }
    &--disabled {
      @apply bg-emerald-300 text-white;
      @apply cursor-not-allowed;
    }
  }
  &--secondary {
    @apply bg-emerald-50 text-emerald-600;
    &:hover {
      @apply bg-emerald-100;
    }
    &--disabled {
      @apply bg-slate-100 text-emerald-300;
      @apply cursor-not-allowed;
    }
  }
  &--clear {
    @apply bg-transparent text-emerald-600;
    &:hover {
      @apply bg-emerald-50 text-emerald-700;
    }
    &--disabled {
      @apply bg-transparent text-slate-300;
      @apply cursor-not-allowed;
    }
  }
  &--outline {
    @apply bg-transparent text-emerald-500 border border-emerald-100;
    &:hover {
      @apply bg-emerald-50;
    }
    &--disabled {
      @apply bg-transparent text-emerald-300 border-emerald-50;
      @apply cursor-not-allowed;
      
    }
  }

  &--xs {
    @apply text-xs h-6 p-2;
  }
  &--sm {
    @apply text-sm h-7 p-2.5;
  }
  &--md {
    @apply text-sm;
  }
}
