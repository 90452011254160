.stat-container {
  @apply flex gap-2 p-0.5 items-center justify-between  rounded-md;
  .icon {
    @apply rounded-md self-center mr-2 p-2 bg-white/30 ;
    img {
      @apply w-11 h-11 mix-blend-multiply;
    }
    p{
      @apply text-xl font-semibold text-slate-900;
    }
  }
  .stat {
    @apply text-2xl font-semibold text-slate-900;
  }
  .label {
    @apply text-sm text-slate-900/70;
  }
  &:nth-child(1) {
    @apply bg-emerald-100 border-2 border-emerald-100;
    .icon {
      // @apply mix-blend-multiply;
    }
  }

  &:nth-child(2) {
    @apply bg-primary-100 border-2 border-primary-100;
    .icon {
      // @apply text-amber-600;
    }
  }

  &:nth-child(3) {
    @apply bg-amber-100 border-2 border-amber-100;
    .icon {
      // @apply text-blue-600;
    }
  }
  &:nth-child(4) {
    @apply bg-blue-100 border-2 border-blue-100;
    .icon {
      // @apply text-blue-600;
    }
  }
  &:nth-child(5) {
    @apply bg-green-100 border-2 border-green-100;
    .icon {
      // @apply text-blue-600;
    }
  }
  &:nth-child(6) {
    @apply bg-red-100 border-2 border-red-100;
    .icon {
      // @apply text-blue-600;
    }
  }
  &:nth-child(7) {
    @apply bg-purple-100 border-2 border-purple-100;
    .icon {
      // @apply text-blue-600;
    }
  }
}
