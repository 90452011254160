
.form-section {
    @apply grid grid-cols-12 gap-5;
    &--left {
      @apply col-span-12 pr-5 mb-4 lg:col-span-3 lg:mb-0 relative;
      &--content {
        @apply sticky top-0;
      }
    }
    &--middle {
      @apply col-span-8 grid grid-cols-1 gap-5 lg:col-span-6 lg:grid-cols-2 lg:mr-8;
      &.full-width {
        @apply col-span-12 lg:col-span-9 lg:grid-cols-1 lg:mr-0;
      }
    }
    &--middle-with-no-left-panel {
      @apply col-span-8 grid grid-cols-1 gap-5 lg:col-span-9 lg:grid-cols-2 lg:mr-8;
      &.full-width {
        @apply col-span-12 lg:col-span-9 lg:grid-cols-1 lg:mr-0;
      }
    }
    &--right {
      @apply hidden md:block col-span-4 lg:col-span-3;
    }
    &:not(:last-of-type) {
      @apply mb-16;
    }
  }

  
// .vertical-form-section {
//   @apply flex flex-col;
//   &--left {
//     @apply col-span-9 lg:col-span-9 lg:grid-cols-2;
//   }
//   &--right {
//     @apply bg-red-300 hidden md:block col-span-3 lg:col-span-3;
//   }
//   &:not(:last-of-type) {
//     @apply mb-12;
//   }
// }