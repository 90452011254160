.app-shell {
  @apply h-screen w-screen flex;
  min-width: 990px;

  // SIDE NAVBAR
  .navbar {
    @apply z-20;
  }
  // RIGHT PANEL
  .main {
    @apply grid flex-1;
    // grid-template-rows: 0px auto;
    &.has-footer {
      grid-template-rows: 64px auto 64px;
    }
    .topbar {
      @apply top-0  w-full px-10 flex items-center justify-between bg-white shadow-lg shadow-slate-200; //  border-b border-dark border-opacity-20
      .icon-link {
        @apply w-6 h-6 opacity-50;
        &:hover {
          @apply cursor-pointer opacity-100;
        }
      }
    }
    .body {
      @apply overflow-hidden;
      // -webkit-overflow-scrolling: touch;
      // -ms-overflow-style: none; /* IE and Edge */
      // scrollbar-width: none; /* Firefox */
      // touch-action: auto;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
    }
    // .footer {
    //   @apply w-full border-t border-dark border-opacity-20 flex items-center px-10 justify-between;
    // }
  }
}
